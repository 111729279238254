<script>
	// import ccSvg from '$lib/assets/images/closed-captioning.svg';
	// import ccSvgOn from '$lib/assets/images/closed-captioning-enabled.svg';
	import playSvg from '$lib/assets/images/circle-play.svg';
	import { smartlookClient } from '$lib/utils/smartlook';
	import BannerText from './BannerText.svelte';
	import videoPoster from '$lib/assets/images/video-poster.webp';

	export let videoSrc = 'https://assets.londontradition.com/London-Tradition-Factory.mp4#t=5';

	let playVideo = true;
	let videoElement = null;
	let showText = true;

	// let showCaptions = false;
	// let videoLoaded = false;

	// $: ccIcon = showCaptions ? ccSvgOn : ccSvg;

	// function toggleCaptions() {
	// 	showCaptions = !showCaptions;
	// 	videoElement.textTracks[0].mode = showCaptions ? 'showing' : 'hidden';
	// }

	function handleVideoClick() {
		playVideo = !playVideo;
		if (videoElement.paused) {
			videoElement.play();
			showText = false;
			smartlookClient.track('video-played');
		} else {
			videoElement.pause();
			showText = true;
			smartlookClient.track('video-paused');
		}
	}

	function handleError() {
		smartlookClient.error('Video failed to load');
	}
</script>

<div
	class="video-container"
	on:click={handleVideoClick}
	on:keydown={(e) => e.key === 'Enter' && handleVideoClick()}
	role="button"
	tabindex="0"
	aria-label="Toggle video playback"
>
	<video
		bind:this={videoElement}
		src={videoSrc}
		poster={videoPoster}
		preload="metadata"
		muted
		loop
		autoplay
		on:error={handleError}
	>
		<track kind="captions" label="English captions" src="/captions.vtt" />
	</video>
	{#if !playVideo}
		<div class="play-icon-container">
			<img class="play-icon" src={playSvg} alt="Play video" />
		</div>
	{/if}
	<!-- <button on:click={toggleCaptions}>
		<img class="cc-icon" src={ccIcon} alt="Closed captions" />
	</button> -->

	{#if showText}
		<BannerText
			title="Made In London"
			text="London Tradition is a British outerwear brand that designs and manufactures premium duffle coats, pea coats, and rain coats in London. We are proud to be a British brand that is committed to supporting British manufacturing and craftsmanship."
		/>
	{/if}
</div>

<style>
	.video-container {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%; /* 16:9 aspect ratio */
		overflow: hidden;
		z-index: 1;
	}

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.video-container video::-webkit-media-controls {
		display: none;
	}

	.play-icon-container {
		position: absolute;
		top: 1rem;
		right: 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 10;
	}

	.play-icon {
		width: 4em;
		height: 4em;
		filter: drop-shadow(0 0.1em 0.2em rgba(0, 0, 0, 0.5));
	}

	@media (max-width: 767px) {
		.play-icon {
			width: 2em;
			height: 2em;
		}
	}

	/* button {
		position: absolute;
		bottom: 1em;
		right: 1em;
		display: flex;
	} */

	/* .cc-icon {
		width: 1.5em;
		height: 1.5em;
		filter: drop-shadow(0 0.1em 0.2em rgba(0, 0, 0, 0.7));
	} */
</style>
