<script>
	import CNN from '$lib/assets/images/cnn.svg';
	import BBC from '$lib/assets/images/bbc.svg';
	import TheGuardian from '$lib/assets/images/the-guardian.svg';

	const cnnLink =
		'https://edition.cnn.com/videos/intl_tv-shows/2015/07/28/spc-traders-london-tradition.cnn';
	const bbcLink = 'https://www.bbc.co.uk/news/av/uk-england-london-27121385';
	const guardianLink =
		'https://www.theguardian.com/fashion/2014/oct/03/duffle-coats-bangladeshi-selling-britishness';
</script>

<section>
	<h2>As seen in</h2>
	<div class="featured-articles">
		<div class="featured-article">
			<a class="featured-logo" href={cnnLink} target="_blank">
				<img src={CNN} alt="CNN logo" height="48" width="100" />
			</a>
			<div class="featured-text">
				<p>Turning British military workwear into coveted fashion items</p>
			</div>
		</div>
		<div class="featured-article">
			<a class="featured-logo" href={bbcLink} target="_blank">
				<img src={BBC} alt="BBC logo" height="48" width="168" />
			</a>
			<div class="featured-text">
				<p>Flying the flag for British business</p>
			</div>
		</div>
		<div class="featured-article">
			<a class="featured-logo" href={guardianLink} target="_blank">
				<img src={TheGuardian} alt="The Guardian logo" height="48" width="146" />
			</a>
			<div class="featured-text">
				<p>Spearheading a manufacturing revival in London’s East End</p>
			</div>
		</div>
	</div>
</section>

<style lang="scss">
	section {
		text-align: center;
		background-color: #f7f8f8;
		padding: 2em 0;
	}

	h2 {
		margin-bottom: 2em;
	}

	.featured-articles {
		display: flex;
		justify-content: space-around;
		flex: 1 1 33%;
	}

	.featured-article {
		display: flex;
		flex-direction: column;
	}

	.featured-text {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 1em;
	}

	.featured-logo {
		img {
			height: 3em;
			margin: 0 1em;
		}
	}

	// mobile styles
	@media (max-width: 767px) {
		.featured-articles {
			flex-direction: column;
			align-items: center;
		}
	}
</style>
