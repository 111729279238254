<script>
	import { page } from '$app/stores';
	import Button from './Button.svelte';
	import ImageNew from './ImageNew.svelte';
	import { createSlug } from '$lib/utils/helpers';

	let featuredProduct = $page.data.featuredProduct;
</script>

<section>
	{#if featuredProduct && featuredProduct.name}
		<div class="featured-product">
			<div class="badge-container">
				<span class="badge">Featured Product</span>
			</div>
			<h1>
				<span>{featuredProduct.name}</span>
				<span class="title-secondary">{featuredProduct.productType}</span>
			</h1>
			<p>{featuredProduct.description}</p>
			<div class="button-container">
				<Button
					href={`/shop/${featuredProduct.category.toLowerCase()}/${createSlug(
						featuredProduct.productType
					)}/${featuredProduct.slug}`}>Shop Now</Button
				>
			</div>
		</div>

		<div class="featured-image-container">
			<ImageNew
				src={featuredProduct.image.src}
				alt="Featured Product"
				imageHeight={featuredProduct.image.height}
				imageWidth={featuredProduct.image.width}
				class="home-featured-image"
				immediate
			/>
		</div>
	{/if}
</section>

<style lang="scss">
	section {
		display: flex;
	}
	.featured-product {
		display: flex;
		flex-direction: column;
		padding: 4em;
		flex: 1;
		justify-content: center;
	}
	.featured-image-container {
		max-width: 50%;
		position: relative;
	}

	:global(.home-featured-image) {
		height: 100% !important;
		object-fit: cover;
	}
	.badge-container {
		padding: 5px 10px;
		width: fit-content;
		border: 1px solid;
		box-shadow:
			2px 2px 0 #aaa,
			-2px -2px 0 #fff;
	}

	.title-secondary {
		display: inline-block;
	}

	.badge {
		white-space: nowrap;
	}

	.button-container {
		margin-top: 1em;
	}

	// mobile styles
	@media (max-width: 767px) {
		section {
			flex-direction: column-reverse;
		}
		.featured-image-container {
			max-width: 100%;
		}
		.featured-product {
			padding: 2em;
		}
	}
</style>
